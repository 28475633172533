import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import { Grid, Box } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollTop: 0,
    };
  }
  render() {
    const { classes } = this.props;
    return (
      <Layout id="top" isTop={true} title="top">
        <Grid container spacing={0} direction="column">
          <Grid item xs id="top">
            <Box className={classes.topImage} />
          </Grid>
        </Grid>
      </Layout >
    );
  }
};

const styles = () => ({
  indexContent: {
    background: "lightgray",
    borderRadius: "4px",
  },
  paper: {
    padding: "1em",
  },
  topImage: {
    width: "100%",
    height: "100vh",
    padding: "10%",
    backgroundImage: "url('/images/top.jpg')",
    backgroundSize: "auto 100%",
    backgroundColor: "black",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  }
});

export default withStyles(styles)(Index);